// portfolio.ts (frontend)
import axios from 'axios';
import { Portfolio, Transaction } from '../types/portfolioTypes';

const API_URL = process.env.NODE_ENV === 'development' ? 
    process.env.REACT_APP_API_LOCAL_URL + '/portfolio' : process.env.REACT_APP_API_BASE_URL + '/portfolio';



// Fetch portfolio by userId
export const fetchPortfolio = async (userId: string): Promise<Portfolio> => {
  try {
    const response = await axios.get(`${API_URL}/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch portfolio:', error);
    throw error;
  }
};

// Create new portfolio
export const createPortfolio = async (userId: string, initialCashBalance: number): Promise<Portfolio> => {
  try {
    const response = await axios.post(`${API_URL}`, { userId, initialCashBalance });
    return response.data;
  } catch (error) {
    console.error('Failed to create portfolio:', error);
    throw error;
  }
};

// Update cash balance
export const updateCashBalance = async (userId: string, cashBalance: number): Promise<void> => {
  try {
    await axios.patch(`${API_URL}/${userId}/cash`, { cashBalance });
  } catch (error) {
    console.error('Failed to update cash balance:', error);
    throw error;
  }
};

// Store transaction
export const storeTransaction = async (userId: string, transaction: Omit<Transaction, 'id' | 'date'>): Promise<Transaction> => {
  try {
    const response = await axios.post(`${API_URL}/${userId}/transactions`, transaction);
    return response.data;
  } catch (error) {
    console.error('Failed to add transaction:', error);
    throw error;
  }
};

// Fetch transactions
export const fetchTransactions = async (userId: string): Promise<Transaction[]> => {
  try {
    const response = await axios.get(`${API_URL}/${userId}/transactions`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch transactions:', error);
    throw error;
  }
};

// Delete portfolio
export const deletePortfolio = async (userId: string): Promise<void> => {
  try {
    await axios.delete(`${API_URL}/${userId}`);
  } catch (error) {
    console.error('Failed to delete portfolio:', error);
    throw error;
  }
};