import styled from 'styled-components';

export const ChartContainer = styled.div`
  width: 100%;
  height: 400px;
  @media (max-width: 768px) {
    height: 300px;
  }
`;

export const StyledTabs = styled.div`
  width: 100%;
`;

export const StyledTabList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0 10px;
  display: flex;
  border-bottom: 1px solid #ccc;
`;

export const StyledTab = styled.li<{ isSelected: boolean }>`
  padding: 10px 20px;
  cursor: pointer;
  opacity: ${props => props.isSelected ? 1 : 0.6};
  border-bottom: 2px solid ${props => props.isSelected ? '#4285f4' : 'transparent'};
  transition: all 0.1s;

  &:hover {
    opacity: 1;
  }
`;

export const StyledTabPanel = styled.div<{ isSelected: boolean }>`
  display: ${props => props.isSelected ? 'block' : 'none'};
  padding: 10px 0;
`;

export const TabContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;