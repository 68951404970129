import React, { useState, ChangeEvent } from 'react';
import styled from 'styled-components';

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledInputElement = styled.input<{ status: 'default' | 'valid' | 'invalid' | 'active' }>`
  border: 2px solid;
  border-color: ${props => {
    switch(props.status) {
      case 'valid':
        return 'green';
      case 'invalid':
        return 'red';
      case 'active':
        return 'blue';
      default:
        return '#ccc';
    }
  }};
  padding: 8px;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  padding-bottom: 0.5rem;
`;

const ErrorMessage = styled.span<{ position: 'left' | 'right' }>`
  color: red;
  font-size: 12px;
  white-space: nowrap;
  position: absolute;
  ${props => props.position === 'left' ? 'right: 100%;' : 'left: 100%;'}
  ${props => props.position === 'left' ? 'margin-right: 10px;' : 'margin-left: 10px;'}
  top: 50%;
  transform: translateY(-50%);
`;

interface StyledInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  validateInput: (value: string) => boolean;
  errorMessage: string;
  errorPosition?: 'left' | 'right';
  onChange?: (value: string) => void;
}

export const StyledInput: React.FC<StyledInputProps> = ({ 
  validateInput, 
  errorMessage, 
  errorPosition = 'left', 
  onChange, 
  ...props 
}) => {
  const [status, setStatus] = useState<'default' | 'valid' | 'invalid' | 'active'>('default');
  const [showError, setShowError] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const isValid = validateInput(newValue);
   
    if (isValid) {
      setStatus('valid');
      setShowError(false);
    } else {
      setStatus('invalid');
      setShowError(true);
    }

    if (onChange) {
      onChange(newValue);
    }
  };

  const handleFocus = () => {
    setStatus('active');
    setShowError(false);
  };

  const handleBlur = () => {
    setStatus('default');
    setShowError(false);
  };

  return (
    <InputWrapper>
      <InputContainer>
        {showError && errorPosition !== 'left' && <ErrorMessage position={errorPosition}>{errorMessage}</ErrorMessage>}
        <StyledInputElement
          {...props}
          status={status}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </InputContainer>
    </InputWrapper>
  );
};