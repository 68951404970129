import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
`;

const MetricTitle = styled.h3`
  margin-bottom: 10px;
  font-size: 1rem;
  color: black;
`;

const MetricValue = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
`;

interface OverviewGridProps {
  totalValue: number;
  cashBalance: number;
  displayCurrency: (amount: number) => string;
}

export const OverviewGrid: React.FC<OverviewGridProps> = ({
  totalValue,
  cashBalance,
  displayCurrency
}) => {
  const { t } = useTranslation();

  return (
    <Grid>
      <div>
        <MetricTitle>{t('portfolio.overview.totalValue')}</MetricTitle>
        <MetricValue style={{color: '#FF6384'}}>{displayCurrency(totalValue)}</MetricValue>
      </div>
      <div>
        <MetricTitle>{t('portfolio.overview.balance')}</MetricTitle>
        <MetricValue style={{color: '#3B9696'}}>{displayCurrency(cashBalance)}</MetricValue>
      </div>
    </Grid>
  );
};