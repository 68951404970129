import React from 'react';
import styled from 'styled-components';

interface PaginationButtonsProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const PageButton = styled.button<{ isActive?: boolean; disabled?: boolean }>`
  background-color: ${props => props.isActive ? '#D30021' : '#f0f0f0'};
  color: ${props => props.isActive ? '#ffffff' : '#333333'};
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  font-size: 14px;
  font-weight: bold;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  transition: all 0.3s ease;
  opacity: ${props => props.disabled ? 0.5 : 1};

  &:hover {
    background-color: ${props => props.isActive ? '#B8001D' : '#e0e0e0'};
  }
`;

const PageInfo = styled.span`
  font-size: 14px;
  color: #666666;
`;

const PlaceholderButton = styled.div`
  width: 36px;
  height: 36px;
`;

const PaginationButtons: React.FC<PaginationButtonsProps> = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    if (i === 1 || i === totalPages || (i >= currentPage - 1 && i <= currentPage + 1)) {
      pageNumbers.push(i);
    } else if (i === currentPage - 2 || i === currentPage + 2) {
      pageNumbers.push('...');
    }
  }

  return (
    <PaginationContainer>
      {currentPage > 1 ? (
        <PageButton onClick={() => onPageChange(currentPage - 1)}>
          &#8592;
        </PageButton>
      ) : (
        <PlaceholderButton />
      )}
      {pageNumbers.map((number, index) => (
        <React.Fragment key={index}>
          {number === '...' ? (
            <PageInfo>...</PageInfo>
          ) : (
            <PageButton
              isActive={number === currentPage}
              onClick={() => onPageChange(number as number)}
            >
              {number}
            </PageButton>
          )}
        </React.Fragment>
      ))}
      {currentPage < totalPages ? (
        <PageButton onClick={() => onPageChange(currentPage + 1)}>
          &#8594;
        </PageButton>
      ) : (
        <PlaceholderButton />
      )}
    </PaginationContainer>
  );
};

export default PaginationButtons;