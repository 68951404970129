import React, { useState, useEffect, useMemo } from 'react';
import { Line } from 'react-chartjs-2';

import { availableAssets, formatCurrency, getCurrency, tempChartData, initialPortfolio, AssetPricesUSD, validateAmount } from './utils/calculations';
import { Asset, ExpectedPriceProps, Portfolio, Transaction } from '../../types/portfolioTypes';
import { OverviewGrid } from './components/OverviewGrid';
import { StyledInput } from './components/StyledInput';
import { useTranslation } from 'react-i18next';
import { AssetItem, AssetList, AssetName, AssetValue, ChartWrapper, Header, LeftColumn, RightColumn, Section, FullWidthSection, Button, FormContainer, StyledSvSelect, TransactionWrapper, ExpectedPriceWrapper, ColumnContainer, ExpectedPriceContainer, ComponentWrapper } from './pageStyle';
import SvButton from '../../components/common/button/SvButton';
import i18n from '../../i18n/config';
import { useAuth } from '../../contexts/AuthContext';
import { deletePortfolio, fetchPortfolio, storeTransaction, createPortfolio } from '../../api/portfolioApi';
import { SvOption } from '../../components/common/select/SvSelect';
import TransactionList from './components/TransactionList';
import { useInfoPopup } from '../../hooks/useInfoPopup';
import InfoPopup from '../../components/common/popup/InfoPopup';
import PortfolioFloatingHeader from './components/PortfolioFloatingHeader';
import useScroll from '../../hooks/useScroll';
import { StyledTab, StyledTabList, StyledTabPanel, StyledTabs, TabContent } from './tabStyles';
import PortfolioPieChart from './components/PortfolioPieChart';



import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';



// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



const PortfolioPage: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { popups, showErrorPopup, showInfoPopup } = useInfoPopup();
  const showFloatingHeader = useScroll(30); // Show header when scrolled 30% down
  const isLocalhost = window.location.hostname === 'localhost';
  
  const [portfolio, setPortfolio] = useState<Portfolio>(initialPortfolio);
  const [selectedAsset, setSelectedAsset] = useState<Asset>(availableAssets[0]);
  const [displayNOK, setDisplayNOK] = useState<boolean>(false);
  const [amount, setAmount] = useState('');
  const [assetAmounts, setAssetAmounts] = useState<{ [key: string]: string }>({});
  const [activeTab, setActiveTab] = useState(0);



  const totalValue = useMemo(() => {
    return portfolio.assets.reduce((total, asset) => {
      const price = AssetPricesUSD[asset.symbol as keyof typeof AssetPricesUSD] || 0;
      return total + (asset.amount * price);
    }, portfolio.cashBalance);
  }, [portfolio.assets, portfolio.cashBalance]);



  useEffect(() => {
    if (!user) {
      showInfoPopup(t('portfolio.popup.loginToSave'), 'top-right', 5000);
    }
  }, [user, showInfoPopup, t]);



  useEffect(() => {
    setDisplayNOK(getCurrency(i18n.language) === 'NOK');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);



  const displayCurrency = (amount: number) => {
    return formatCurrency(amount, displayNOK);
  };



  const chartOptions = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: `${selectedAsset?.name} History`,
      },
    },
    scales: {
      y: {
        beginAtZero: false,
      },
    },
  }), [selectedAsset]);

  const chartData = useMemo(() => selectedAsset ? {
    labels: tempChartData[selectedAsset.symbol as keyof typeof tempChartData]?.map(item => item.date) || [],
    datasets: [
      {
        label: selectedAsset.name,
        data: tempChartData[selectedAsset.symbol as keyof typeof tempChartData]?.map(item => item.price) || [],
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  } : null, [selectedAsset]);



  const handleDeletePortfolio = async () => {
    if (user) {
      try {
        await deletePortfolio(user.id);
        setPortfolio(initialPortfolio);
        showInfoPopup(t('portfolio.popup.deleteSuccess'));
      } catch (error) {
        showErrorPopup(t('portfolio.popup.deleteError'));
      }
    }
  }



  // GET portfolio for user - IF pro
  useEffect(() => {
    const updatePortfolio = async () => {
      if (portfolio === initialPortfolio) {
        if (user && user.isPro) {
          try {
            const fetchedPortfolio = await fetchPortfolio(user.id);
            
            const updatedAssets = fetchedPortfolio.assets.map(asset => ({
              ...asset,
              price: AssetPricesUSD[asset.symbol as keyof typeof AssetPricesUSD] || 0
            }));
    
            const updatedPortfolio = {
              ...fetchedPortfolio,
              assets: updatedAssets
            };
    
            setPortfolio(updatedPortfolio);
            if (isLocalhost) console.log('Updated Portfolio:', user.id);
          } catch (error) {
            if (isLocalhost) console.error('Error fetching portfolio:', error);
          }
        } 
      };
    }
    updatePortfolio();
  }, [user, portfolio, isLocalhost]);



  const refreshPortfolio = async () => {
    if (user && user.isPro) {
      try {
        const updatedPortfolio = await fetchPortfolio(user.id);
        const updatedAssets = updatedPortfolio.assets.map(asset => ({
          ...asset,
          price: AssetPricesUSD[asset.symbol as keyof typeof AssetPricesUSD] || 0
        }));
        setPortfolio({
          ...updatedPortfolio,
          assets: updatedAssets
        });
      } catch (error) {
        showErrorPopup(t('portfolio.popup.refreshError'));
      }
    }
  }



  const isValidTransactionType = (type: string): type is 'buy' | 'sell' | 'add' => 
    type === 'buy' || type === 'sell' || type === 'add';



  // POST new transaction
  const newTransaction = async (transactionType: string, transactionAsset?: Asset) => {
    if (!user) {
      showErrorPopup(t('portfolio.popup.loginRequired'));
      return;
    }
  
    if (isValidTransactionType(transactionType)) {
      let transactionAmount: number = 0;
  
      if (transactionAsset) {
        transactionAmount = parseFloat(assetAmounts[transactionAsset.symbol] || '0');
      } else {
        transactionAmount = parseFloat(amount);
      }
  
      if (isNaN(transactionAmount) || transactionAmount <= 0) {
        showErrorPopup(t('portfolio.popup.invalidAmount'));
        return;
      }
  
      // Check if portfolio exists, if not, create one
      if (portfolio === initialPortfolio && user.isPro) {
        try {
          const newPortfolio = await createPortfolio(user.id, 0);  // Create with 0 balance
          setPortfolio(newPortfolio);
        } catch (error) {
          showErrorPopup(t('portfolio.popup.portfolioCreationError'));
          return;
        }
      }

      const transaction: Omit<Transaction, 'id' | 'date'> = transactionAsset 
      ? {
          assetSymbol: transactionAsset.symbol,
          assetName: transactionAsset.name,
          type: transactionType,
          amount: transactionAmount,
          price: transactionAsset.price
        }
      : {
          assetSymbol: selectedAsset.symbol,
          assetName: selectedAsset.name,
          type: transactionType,
          amount: transactionAmount,
          price: selectedAsset.price
        };


      if (transactionType === 'buy') {
        if (transaction.price * transaction.amount > portfolio.cashBalance) {
          showErrorPopup(t('portfolio.popup.insufficientCash'));
          return;
        }
      }


      if (transactionType === 'sell') {
        const assetInPortfolio = portfolio.assets.find(asset => asset.symbol === transaction.assetSymbol);
        if (!assetInPortfolio || assetInPortfolio.amount < transaction.amount) {
          showErrorPopup(t('portfolio.popup.insufficientAsset'));
          return;
        }
      }


      if (transactionType === 'add') {
        if (transaction.amount === 0) {
          showErrorPopup(t('portfolio.popup.invalidAmount'));
          return;
        }
      }


      if (user && user.isPro) {
        try {
          await storeTransaction(user.id, transaction);
          await refreshPortfolio();
          setAmount('');
          showInfoPopup(t('portfolio.popup.transactionSuccess'));
        } catch (error) {
          showErrorPopup(t('portfolio.popup.transactionError'));
        }
      }
    }
  }



  const sellAll = async (asset: Asset) => {

    const transaction: Omit<Transaction, 'id' | 'date'> = {
          assetSymbol: asset.symbol,
          assetName: asset.name,
          type: 'sell',
          amount: asset.amount,
          price: asset.price
        };

    if (user && user.isPro) {
      await storeTransaction(user.id, transaction);
      await refreshPortfolio();
    }
  }



  const ExpectedPrice: React.FC<ExpectedPriceProps> = ({ asset, amount }) => {
    const expectedValue = parseFloat(amount) * asset.price;
    const isPositive = expectedValue > 0;

    if (isNaN(expectedValue) || expectedValue === 0) return null;

    return (
      <ExpectedPriceWrapper isPositive={isPositive}>
        {displayCurrency(expectedValue)}
      </ExpectedPriceWrapper>
    );
  };



  return (
    <ComponentWrapper>
      <PortfolioFloatingHeader 
        totalValue={totalValue}
        cashBalance={portfolio.cashBalance}
        displayCurrency={displayCurrency}
        show={showFloatingHeader}
      />
      <ColumnContainer>
      <LeftColumn>
        <Section>
          <Header>{t('portfolio.name')}</Header>
          <OverviewGrid 
            totalValue={totalValue} 
            cashBalance={portfolio.cashBalance}
            displayCurrency={displayCurrency}
          />
        </Section>
        <Section>
          <div>
            <h3>{t('portfolio.addAsset')}</h3>
            <FormContainer>
              <StyledSvSelect
                size={'medium'}
                onChange={(e) => {
                  setSelectedAsset(availableAssets[e.target.selectedIndex]);
                }}
              >
                {availableAssets.map((asset) => (
                  <SvOption key={asset.symbol} value={asset.symbol}>
                    {asset.symbol} ({asset.name}) - {displayCurrency(asset.price)}
                  </SvOption>
                ))}
              </StyledSvSelect>
              
              <StyledInput
                type="number"
                placeholder={t('portfolio.amount')}
                value={amount}
                onChange={(value: string) => setAmount(value)}
                validateInput={validateAmount}
                errorMessage={t('portfolio.popup.invalidAmount')}
                errorPosition="right"
                step="0.000001"
                min="0"
              />
            </FormContainer>
            <ExpectedPriceContainer>
              <Button onClick={() => newTransaction('add')}>{t('portfolio.addAssetShort')}</Button>
              {parseFloat(amount) > 0 && (
                <ExpectedPrice asset={selectedAsset} amount={amount} />
              )}
            </ExpectedPriceContainer>
          </div>
        </Section>
        <Button style={{backgroundColor: 'grey'}} onClick={handleDeletePortfolio}>{t('portfolio.deletePortfolio')}</Button>
      </LeftColumn>
        
      <RightColumn>
        <Section style={{ height: '100%' }}>
          <StyledTabs>
            <StyledTabList>
              <StyledTab 
                isSelected={activeTab === 0} 
                onClick={() => setActiveTab(0)}
              >
                {t('portfolio.assetAllocation')}
              </StyledTab>
              <StyledTab 
                isSelected={activeTab === 1} 
                onClick={() => setActiveTab(1)}
              >
                {t('portfolio.priceChart')}
              </StyledTab>
            </StyledTabList>
            <TabContent>
              <StyledTabPanel isSelected={activeTab === 0}>
                <PortfolioPieChart
                  assets={portfolio.assets}
                  cash={portfolio.cashBalance}
                  displayCurrency={displayCurrency}
                />
              </StyledTabPanel>
              <StyledTabPanel isSelected={activeTab === 1}>
                <ChartWrapper>
                  {chartData && (
                    <Line options={chartOptions} data={chartData} />
                  )}
                </ChartWrapper>
              </StyledTabPanel>
            </TabContent>
          </StyledTabs>
        </Section>
      </RightColumn>
      </ColumnContainer>

      <FullWidthSection>
        <h2>
        {t('portfolio.assets')} ( {portfolio.assets.filter(asset => asset.amount > 0).length} )
        </h2>
        <AssetList>
        {portfolio.assets
          .filter(asset => asset.amount > 0)
          .map((asset) => (
            <AssetItem key={asset.symbol}>
              <AssetName>{asset.name} ({asset.symbol})</AssetName>
              <AssetValue>
                {t('portfolio.price')}: {displayCurrency(asset.price)}
              </AssetValue>
              <AssetValue>
                {t('portfolio.amount')}: {asset.amount}
              </AssetValue>
              <AssetValue>
                {t('portfolio.value')}: {displayCurrency(asset.amount * asset.price)}
              </AssetValue>
              <TransactionWrapper>
                <ExpectedPrice asset={asset} amount={assetAmounts[asset.symbol]} />
                <StyledInput
                  type="number"
                  placeholder={t('portfolio.amount')}
                  step="0.000001"
                  min="0"
                  value={assetAmounts[asset.symbol] || ''}
                  onChange={(value: string) => {
                    setAssetAmounts({...assetAmounts, [asset.symbol]: value});
                  }}
                  validateInput={validateAmount}
                  errorMessage={t('portfolio.popup.invalidAmountShort')}
                  onBlur={() => setAssetAmounts({...assetAmounts, [asset.symbol]: ''})}
                />
                <SvButton variant="primary" onClick={() => newTransaction('buy', asset)}>{t('portfolio.buy')}</SvButton>
                <SvButton variant="outline" onClick={() => newTransaction('sell', asset)}>{t('portfolio.sell')}</SvButton>
                <SvButton variant="outline" onClick={() => sellAll(asset)} style={{ marginLeft: '10px' }}>{t('portfolio.sellAll')}</SvButton>
              </TransactionWrapper>
            </AssetItem>
          ))}
        </AssetList>
      </FullWidthSection>
      
      <FullWidthSection>
        <TransactionList transactions={portfolio.transactions} displayCurrency={displayCurrency} />
      </FullWidthSection>

      {popups.map((popup) => (
        <InfoPopup
          key={popup.id}
          message={popup.message}
          type={popup.type}
          position={popup.position}
          timeout={popup.duration}
        />
      ))}
    </ComponentWrapper>
  );
};



export default PortfolioPage;