import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { useInfoPopup } from '../../../hooks/useInfoPopup';
import { WatchlistItem } from '../types';

import InfoPopup from '../../../components/common/popup/InfoPopup';

import { 
  WatchlistContainer, 
  WatchlistHeader, 
  WatchlistTitle, 
  ClearButton, 
  WatchlistUnit, 
  GemInfo, 
  GemSymbol, 
  RemoveButton, 
  EmptyWatchlistMessage,
  ShowFirstToggleContainer,
  ToggleLabel,
  ToggleSwitch,
  Slider
} from './watchlistStyles';



interface WatchlistProps {
  watchlist: WatchlistItem[];
  onRemove: (assetId: string) => void;
  onClear: () => Promise<void>;
  showFirst: boolean;
  onShowFirstToggle: (newShowFirst: boolean) => void;
  isLoading: boolean;
}



const Watchlist: React.FC<WatchlistProps> = ({ 
  watchlist, 
  onRemove, 
  onClear, 
  showFirst, 
  onShowFirstToggle,
  isLoading
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { popups, showErrorPopup, showInfoPopup } = useInfoPopup();
  
  const [isToggleReady, setIsToggleReady] = useState(false);


  
  useEffect(() => {
    if (!isLoading) {
      setIsToggleReady(true);
    }
  }, [isLoading]);


  
  const handleRemove = (e: React.MouseEvent, assetId: string) => {
    e.stopPropagation();
    onRemove(assetId);
  };



  const handleClear = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (user) {
      try {
        await onClear();
        showInfoPopup(t('discovery.popup.watchlist.cleared'));
      } catch (error) { 
        showErrorPopup(t('discovery.popup.watchlist.updateError'));
      }
    } else {
      showErrorPopup(t('discovery.popup.watchlist.loginRequired'));
    }
  };



  return (
    <WatchlistContainer>

      <WatchlistHeader>
        <WatchlistTitle>{t('discovery.watchlist.title')}</WatchlistTitle>
        {isToggleReady && (
          <ShowFirstToggleContainer>
            <ToggleLabel>{t('discovery.watchlist.showFirst')}</ToggleLabel>
            <ToggleSwitch>
              <input
                type="checkbox"
                checked={showFirst}
                onChange={() => onShowFirstToggle(!showFirst)}
              />
              <Slider />
            </ToggleSwitch>
          </ShowFirstToggleContainer>
        )}
        {watchlist.length > 0 && <ClearButton onClick={handleClear}>{t('discovery.watchlist.clear')}</ClearButton>}
      </WatchlistHeader>

      {watchlist.length > 0 ? (
        watchlist.map(gem => (
          <WatchlistUnit key={gem.id} onClick={() => navigate(`/gems/${gem.symbol}`)}>
            <GemInfo>
              <GemSymbol>{gem.symbol}</GemSymbol>
            </GemInfo>
            <RemoveButton onClick={(e) => handleRemove(e, gem.id)}>{t('discovery.watchlist.remove')}</RemoveButton>
          </WatchlistUnit>
        ))
      ) : (
        <EmptyWatchlistMessage>
          {t('discovery.watchlist.emptyMessage', 'Add items to your watchlist for quick access')}
        </EmptyWatchlistMessage>
      )}
      {popups.map((popup) => (
        <InfoPopup
          key={popup.id}
          message={popup.message}
          type={popup.type}
          position={popup.position}
          timeout={popup.duration}
        />
      ))}
    </WatchlistContainer>
  );
};

export default Watchlist;