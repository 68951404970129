import styled, { css } from 'styled-components';

interface ButtonProps {
  scale?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary' | 'outline' | 'danger' | 'dangerOutline';
  isActive?: boolean;
  disabled?: boolean;
}

const SvButton = styled.button<ButtonProps>`
  ${({ scale, theme, size = 'small', variant = 'outline', isActive = false, disabled = false }) => {
    const buttonSize = variant === 'primary' && !scale ? 'medium' : 'small';
   
    const adjustedPadding = (padding: string) => {
      const [vertical, horizontal] = padding.split(' ');
      return `calc(${vertical} - 2px) ${horizontal}`;
    };
    const adjustedHeight = (height: string) => `calc(${height} - 4px)`;
   
    return css`
      padding: ${variant === 'outline'
        ? adjustedPadding(theme.button.sizes[size].padding)
        : theme.button.sizes[size].padding};
      margin: ${theme.button.sizes[size].margin};
      font-size: ${theme.button.sizes[buttonSize].fontSize};
      height: ${scale
        ? (variant === 'outline'
          ? adjustedHeight(theme.button.sizes[buttonSize].height)
          : theme.button.sizes[buttonSize].height)
        : 'auto'};
      background-color: ${disabled
        ? theme.button.variants[variant].disabledBackground
        : isActive
        ? theme.button.variants[variant].activeBackground
        : theme.button.variants[variant].background};
      color: ${disabled
        ? theme.button.variants[variant].disabledColor
        : isActive
        ? theme.button.variants[variant].activeColor
        : theme.button.variants[variant].color};
      border: ${theme.button.variants[variant].border};
      border-radius: ${theme.button.borderRadius};
      font-weight: ${theme.button.fontWeight};
      transition: ${theme.button.transition};
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      min-width: ${scale ? '80px' : 'auto'};
      box-sizing: border-box;
      opacity: ${disabled ? 0.6 : 1};
     
      &:hover {
        background-color: ${!disabled && theme.button.variants[variant].hoverBackground};
        color: ${!disabled && theme.button.variants[variant].hoverColor};
      }
      &:active {
        background-color: ${!disabled && theme.button.variants[variant].activeBackground};
        color: ${!disabled && theme.button.variants[variant].activeColor};
      }
       
      ${variant === 'outline' && isActive && !scale && !disabled && css`
        border: ${theme.button.variants.outline.activeBorder || theme.button.variants.outline.border};
      `}
    `;
  }}
`;

export default SvButton;