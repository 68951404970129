import React, { useCallback, useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import AuthButton from '../auth/AuthButton';
import UpgradeModal from '../../pages/upgrade/components/UpgradeModal';
import { useAuth } from '../../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import useScroll from '../../hooks/useScroll';
import { SuccessModal } from '../success/SuccessModal';
import * as Styled from './floatingHeaderStyles';
import PointsDisplay from './PointsDisplay';



interface StarIconProps {
  top: string;
  left: string;
  size: string;
  duration: number;
  delay: number;
}



const StarIcon: React.FC<StarIconProps> = ({ top, left, size, duration, delay }) => (
  <Styled.StarWrapper top={top} left={left} size={size} duration={duration} delay={delay}>
    <FaStar />
  </Styled.StarWrapper>
);



const FloatingHeader: React.FC = () => {
  const { t } = useTranslation();
  const { user, updateUser } = useAuth();
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const location = useLocation();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const hideHeader = useScroll(25);
  const navigate = useNavigate();


  
  const handleSuccessRedirect = useCallback((sessionId: string) => {
    setSessionId(sessionId);
    setIsSuccessModalOpen(true);
    
    const cleanUrl = new URL(window.location.href);
    cleanUrl.searchParams.delete('session_id');
    
    navigate(cleanUrl.pathname + cleanUrl.search, { replace: true });
  }, [navigate]);



  const handlePaymentConfirmed = useCallback(() => {
    updateUser();
  }, [updateUser]);
 

  
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const sessionIdParam = urlParams.get('session_id');
    if (sessionIdParam) {
      handleSuccessRedirect(sessionIdParam);
    }
  }, [location, handleSuccessRedirect, navigate]);
 


  const handleUpgradeClick = () => {
    setIsUpgradeModalOpen(true);
  };
 


  const logoSrc = location.pathname.includes('/gems') ? '/simple/head_1.png' : '/simple/full_2.png';
  const logoStyle = logoSrc.includes('red_logo_1.png') ? { width: '50px' } : {};



  return (
    <Styled.HeaderWrapper>
      <Styled.FloatingLogo to="/" $hide={hideHeader}>
        <Styled.LogoImage src={logoSrc} alt="trailvis Logo" style={logoStyle} />
      </Styled.FloatingLogo>
      <Styled.ButtonGroup $hide={hideHeader}>
        {(!user || (user && !user.isPro)) && (
          <Styled.UpgradeButton onClick={handleUpgradeClick}>
            <Styled.ButtonContent>
              {t('upgrade.button', 'Upgrade')}
            </Styled.ButtonContent>
            {[...Array(5)].map((_, index) => (
              <StarIcon
                key={index}
                top={`${Math.random() * 100}%`}
                left={`${Math.random() * 100}%`}
                size={`${Math.random() * 10 + 5}px`}
                duration={Math.random() * 2 + 1}
                delay={Math.random() * 2}
              />
            ))}
          </Styled.UpgradeButton>
        )}
        {user && user.isPro && (
          <>
            <Styled.CrownIcon />
            <PointsDisplay />
          </>
        )}
        <AuthButton />
      </Styled.ButtonGroup>
      
      <UpgradeModal
        isopen={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
      />
  
      <SuccessModal 
        isOpen={isSuccessModalOpen} 
        onClose={() => {
          setIsSuccessModalOpen(false);
        }} 
        sessionId={sessionId}
        onPaymentConfirmed={handlePaymentConfirmed}
      />  
    </Styled.HeaderWrapper>
  );
};



export default FloatingHeader;