import axios from 'axios';
import { Gem, GemResponse, NewGemRequest } from '../pages/discovery/types';
import apiService from './baseApi';



const API_URL = process.env.NODE_ENV === 'production' ? 
process.env.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_LOCAL_URL;


// POST cast vote
export const voteGem = async (userId: string, gemId: string, vote: 1 | 0 | -1): Promise<void> => {
  try {
    await apiService.post(`/gems/cast-vote`, {
      userId: userId,
      gemId: gemId,
      vote
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.error || 'An error occurred while voting');
    }
    throw error;
  }
}



// GET all from gems
export const fetchGems = async (): Promise<GemResponse> => {
  try {
    const response = await axios.get<GemResponse>(`${API_URL}/gems/all`, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': process.env.REACT_APP_API_KEY
      },
      withCredentials: true,
      timeout: 5000 // 5 seconds timeout
    });
    console.log('Gems Response:', response.data);
    return response.data;

  } catch (error: unknown) {
    console.error('Error fetching gems:', error);
    if (axios.isAxiosError(error)) {
      console.error('Axios error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        config: error.config
      });
      throw new Error(`Failed to fetch gems: ${error.message}`);
    } else if (error instanceof Error) {
      console.error('Error details:', {
        name: error.name,
        message: error.message,
        stack: error.stack
      });
      throw new Error(`Failed to fetch gems: ${error.message}`);
    } else {
      console.error('Unknown error type:', error);
      throw new Error('An unknown error occurred while fetching gems');
    }
  }
};



// GET gem by id
export const fetchGemById = async (id: string): Promise<Gem | null> => {
  try {
    const response = await apiService.get<Gem>(`/gems/gemById/${id}`);
    return response;
  } catch (error) {
    return null;
  }
};



// GET votes per gem
export const getGemVotes = async (gemId: string): Promise<any> => {
  try {
    const response = await apiService.get<any>(`/gems/votes/${gemId}`);
    return response;
  } catch (error) {
    throw error;
  }
};



// POST add new gem
export const addNewGem = async (username: string, userId: string, name: string, symbol: string, price: number, tags: string[]): Promise<Gem> => {
  try {
    const newGem: NewGemRequest = {
      user: username,
      userId,
      name,
      symbol,
      price,
      tags: tags,
    };

    const response = await apiService.post<Gem>(`/gems/new`, newGem);

    // Ensure the returned data has all required properties
    const returnedGem: Gem = {
      ...response
    };

    return returnedGem;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        throw new Error(error.response.data.error || 'An error occurred while adding new gem');
      } else if (error.request) {
        throw new Error('No response received from server');
      } else {
        throw new Error('Error setting up the request');
      }
    }
    throw new Error('An unexpected error occurred');
  }
};



// POST clear votes
export const clearVotes = async (userId: string): Promise<void> => {
  try {
    await apiService.post(`/gems/clear-user-votes`, { userId });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.error || 'An error occurred while clearing votes');
    }
    throw error;
  }
};



export const testGet = async (): Promise<any> => {
  console.log('[INFO] Api - Testing get');
  try {
    const response = await axios.get<any>(`${API_URL}/gems/test-get`, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': process.env.REACT_APP_API_KEY
      },
      withCredentials: true
    });
    console.log('response:', response);
    return response;
  } catch (error) {
    throw error;
  }
};
