import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { ChartContainer } from '../tabStyles';
import { colorStuff } from '../utils/calculations';

interface Asset {
  symbol: string;
  name: string;
  amount: number;
  price: number;
}

interface PortfolioPieChartProps {
  assets: Asset[];
  cash: number;
  displayCurrency: (value: number) => string;
}

const PortfolioPieChart: React.FC<PortfolioPieChartProps> = ({ assets, cash, displayCurrency }) => {
  const PERCENTAGE_LIMIT = 2;

  const assetColours = colorStuff(assets.length, cash);

  const data = [
    ...(cash > 0 ? [{ name: 'CASH', value: cash }] : []),
    ...assets
      .filter(asset => asset.amount > 0)
      .map(asset => ({
        name: asset.symbol,
        value: asset.amount * asset.price
      }))
  ];  

  const renderLegend = (props: any) => {
    const { payload } = props;
    
    const isMobile = window.innerWidth <= 768;
    
    return (
      <ul style={{
        display: 'flex',
        flexDirection: isMobile ? 'row' : 'column',
        flexWrap: isMobile ? 'wrap' : 'nowrap',
        justifyContent: isMobile ? 'center' : 'flex-start',
        alignItems: isMobile ? 'center' : 'flex-start',
        listStyle: 'none',
        padding: 0,
        margin: 0
      }}>
        {payload.map((entry: any, index: number) => (
          <li key={`item-${index}`} style={{ margin: isMobile ? '0 10px' : '5px 0' }}>
            <span style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              backgroundColor: entry.color,
              marginRight: '5px'
            }}></span>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <ChartContainer>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius="80%"
            innerRadius="30%"
            fill="#8884d8"
            dataKey="value"
            labelLine={false}
            label={({ name, percent }) => 
              percent >= PERCENTAGE_LIMIT / 100 ? `${name} ${(percent * 100).toFixed(0)}%` : ''
            }
          >
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={assetColours[index % assetColours.length]} stroke="black" />
            ))}
          </Pie>
          <Tooltip
            formatter={(value: number) => displayCurrency(value)}
            labelFormatter={(label: string) => `Asset: ${label}`}
          />
          <Legend 
            content={renderLegend}
            layout={window.innerWidth <= 768 ? "horizontal" : "vertical"}
            align={window.innerWidth <= 768 ? "center" : "right"}
            verticalAlign={window.innerWidth <= 768 ? "bottom" : "middle"}
          />
        </PieChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default PortfolioPieChart;