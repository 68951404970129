import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

export const ModalContent = styled.div`
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 500px;
  border: 1px solid #000;

  @media (max-width: 600px) {
    width: 95%;
  }
`;

export const ModalHeader = styled.div`
  background-color: #f0f0f0;
  padding: 8px 20px;
  border-bottom: 1px solid black;
`;

export const ModalTitle = styled.h2`
  margin: 0;
  color: #333;
  font-size: 20px;
`;

export const ModalBody = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
`;

export const Input = styled.input`
  width: 100%;
  border: 1px solid black;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 5px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Button = styled.button`
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
`;

export const AddButton = styled(Button)`
  background-color: #ff3333;
  color: white;
  border: 1px solid black;
  &:hover {
    background-color: #ff0000;
  }
`;

export const CancelButton = styled(Button)`
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid black;
  &:hover {
    background-color: #e0e0e0;
  }
`;

export const ClearButton = styled(Button)`
  background-color: #ffb3ba;
  color: white;
  border: 1px solid black;
  &:hover {
    background-color: #e6959e;
  }
`;