import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface FloatingHeaderProps {
  totalValue: number;
  cashBalance: number;
  displayCurrency: (amount: number) => string;
  show: boolean;
}

const HeaderWrapper = styled.div<{ show: boolean }>`
  margin-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 15px 25px 5px 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  transform: translateY(${props => props.show ? '0' : '-100%'});
  z-index: 1000;
`;

const ValueItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 3rem;
`;

const Label = styled.span`
  font-size: 0.8rem;
  color: #666;
`;

const Value = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
`;

const PortfolioFloatingHeader: React.FC<FloatingHeaderProps> = ({ totalValue, cashBalance, displayCurrency, show }) => {
  const { t } = useTranslation();

  return (
    <HeaderWrapper show={show}>
      <ValueItem style={{color: '#FF6384'}}>
        <Label>{t('portfolio.overview.totalValue')}</Label>
        <Value>{displayCurrency(totalValue)}</Value>
      </ValueItem>
      <ValueItem style={{color: '#3B9696'}}>
        <Label>{t('portfolio.overview.balance')}</Label>
        <Value>{displayCurrency(cashBalance)}</Value>
      </ValueItem>
    </HeaderWrapper>
  );
};

export default PortfolioFloatingHeader;