import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { addNewGem } from '../../api/gemApi';
import { Gem } from './types';
import { Autocomplete, TextField, Chip, Typography } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';

import * as Styled from './addNewGemModalStyles';

interface AddNewGemModalProps {
  isOpen: boolean;
  onClose: () => void;
  onGemAdded: (newGem: Gem) => void;
}

const MAX_TAGS = 10;
const MAX_TAG_LENGTH = 16;

const popularTags = ['crypto', 'stock', 'defi', 'nft', 'growth', 'value'];

const AddNewGemModal: React.FC<AddNewGemModalProps> = ({ isOpen, onClose, onGemAdded }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [symbol, setSymbol] = useState('');
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [tags, setTags] = useState<string[]>(['crypto']);
  const [inputValue, setInputValue] = useState('');
  const [errors, setErrors] = useState<{[key: string]: string}>({});
  const modalRef = useRef<HTMLDivElement>(null);



  useEffect(() => {
    const validateForm = () => {
      // Viser ikke tekst ved feil nå. rever helperText={errors.name}
      const newErrors: {[key: string]: string} = {};
      if (symbol && !/^[A-Z0-9-]{1,10}$/.test(symbol)) {
        newErrors.symbol = t('discovery.addGem.errors.invalidSymbol');
      }
      if (name && name.length < 2) {
        newErrors.name = t('discovery.addGem.errors.nameTooShort');
      }
      if (price && (isNaN(Number(price)) || Number(price) <= 0)) {
        newErrors.price = t('discovery.addGem.errors.invalidPrice');
      }
      if (tags.length === 0) {
        newErrors.tags = t('discovery.addGem.errors.noTags');
      }
      setErrors(newErrors);
    };

    validateForm();
  }, [symbol, name, price, tags, t]);



  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (Object.keys(errors).length > 0 || tags.length === 0) {
      return;
    }
    try {
      if (!user) {
        throw new Error('User is not authenticated');
      }
      const newGem = await addNewGem(user.username, user.id, name, symbol, Number(price), tags);

      onGemAdded(newGem);
      onClose();
    } catch (error) {
      console.error('Error adding new gem:', error);
    }
  };



  const handleClear = () => {
    setSymbol('');
    setName('');
    setPrice('');
    setTags([]);
    setInputValue('');
    setErrors({});
  };



  const handleInputChange = (_: React.ChangeEvent<{}>, newInputValue: string) => {
    if (tags.length >= MAX_TAGS) return;
    
    setInputValue(newInputValue);
    if (newInputValue.endsWith(' ') && newInputValue.trim() !== '') {
      const newTag = newInputValue.trim().slice(0, MAX_TAG_LENGTH);
      if (!tags.includes(newTag)) {
        setTags([...tags, newTag]);
      }
      setInputValue('');
    }
  };



  const handleTagChange = (_: React.ChangeEvent<{}>, newValue: string[]) => {
    const truncatedTags = newValue.map(tag => tag.slice(0, MAX_TAG_LENGTH));
    setTags(truncatedTags.slice(0, MAX_TAGS));
  };



  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 0 && !isNaN(Number(value)))) {
      setPrice(value);
    }
  };



  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);



  if (!isOpen) return null;



  return (
    <Styled.ModalOverlay>
      <Styled.ModalContent ref={modalRef}>
        <Styled.ModalHeader>
          <Styled.ModalTitle>{t('discovery.addGem.title')}</Styled.ModalTitle>
        </Styled.ModalHeader>
        <Styled.ModalBody>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label={t('discovery.addGem.symbol')}
              value={symbol}
              onChange={(e) => setSymbol(e.target.value.toUpperCase())}
              error={!!errors.symbol}
              margin="dense"
            />
            <TextField
              fullWidth
              label={t('discovery.addGem.name')}
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={!!errors.name}
              margin="dense"
            />
            <TextField
              fullWidth
              label={t('discovery.addGem.price')}
              value={price}
              onChange={handlePriceChange}
              error={!!errors.price}
              margin="dense"
            />

            <Autocomplete
              style={{ marginTop: '0.5rem' }}
              multiple
              id="tags"
              options={popularTags}
              freeSolo
              value={tags}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              onChange={handleTagChange}
              renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip
                    variant="outlined"
                    label={<Typography sx={{ fontWeight: 'bold' }}>{option}</Typography>}
                    {...getTagProps({ index })}
                    sx={{
                      borderRadius: '6px',
                      height: '24px',
                      '& .MuiChip-label': {
                        padding: '0 8px',
                      },
                    }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t('discovery.addGem.tags.input')}
                  placeholder={tags.length >= MAX_TAGS ? 'MAX' : ''}
                  helperText={`${tags.length}/${MAX_TAGS} (${t('discovery.addGem.tags.atLeastOneRequired')})`}
                  error={!!errors.tags}
                />
              )}
            />

            <Styled.ButtonContainer>
              <Styled.CancelButton onClick={onClose}>{t('discovery.addGem.cancel')}</Styled.CancelButton>
              <Styled.ClearButton onClick={handleClear}>{t('discovery.addGem.clear')}</Styled.ClearButton>
              <Styled.AddButton type="submit" disabled={Object.keys(errors).length > 0 || tags.length === 0}>
                {t('discovery.addGem.submit')}
              </Styled.AddButton>
            </Styled.ButtonContainer>
          </form>
        </Styled.ModalBody>
      </Styled.ModalContent>
    </Styled.ModalOverlay>
  );
};

export default AddNewGemModal;