import React from 'react';

interface ProgressBarProps {
  value: number;
  max?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value, max = 100 }) => {
  const percentage = Math.min(Math.max(value, 0), max) / max * 100;

  return (
    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
      <div 
        className="bg-blue-600 h-2.5 rounded-full dark:bg-blue-500 transition-all duration-300 ease-in-out"
        style={{ width: `${percentage}%` }}
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={max}
      >
      </div>
    </div>
  );
};

export default ProgressBar;