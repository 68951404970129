import { Link } from "react-router-dom";
import styled from "styled-components";

import { keyframes } from "styled-components";
import theme from "../../styles/theme";
import { FaCrown } from "react-icons/fa";

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  z-index: 1000;
`;

export const fadeOutUpAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

export const fadeInDownAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const FloatingLogo = styled(Link)<{ $hide: boolean }>`
  position: fixed;
  top: 26px;
  left: 24px;
  width: 100px;
  height: auto;
  z-index: 1000;
  @media (max-width: 768px) {
    top: 1rem;
    left: 1rem;
    width: 80px;
  }
  animation: ${props => props.$hide ? fadeOutUpAnimation : fadeInDownAnimation} 0.2s ease-in-out forwards;
  pointer-events: ${props => props.$hide ? 'none' : ''};
`;

export const LogoImage = styled.img`
  width: 100%;
  height: auto;
`;

export const ButtonGroup = styled.div<{ $hide: boolean }>`
  position: fixed;
  top: 26px;
  right: 24px;
  display: flex;
  gap: 10px;
  z-index: 1000;
  animation: ${props => props.$hide ? fadeOutUpAnimation : fadeInDownAnimation} 0.2s ease-in-out forwards;
  pointer-events: ${props => props.$hide ? 'none' : ''};
`;

export const twinkleAnimation = keyframes`
  0%, 100% { opacity: 0.2; transform: scale(0.8) rotate(${Math.random() * 5}deg); }
  25% { opacity: 0.6; transform: scale(0.9) rotate(${40 + Math.random() * 10}deg); }
  50% { opacity: 1; transform: scale(1) rotate(${85 + Math.random() * 10}deg); }
  75% { opacity: 0.6; transform: scale(0.9) rotate(${40 + Math.random() * 10}deg); }
`;

export const UpgradeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.primaryYellow};
  color: #333;
  border: 1px solid black;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.6rem 1rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: ${theme.colors.primaryYellowHover};
  }
`;

export const ButtonContent = styled.span`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
`;

export const StarWrapper = styled.div<{ top: string; left: string; size: string; duration: number; delay: number }>`
  position: absolute;
  color: ${theme.colors.lightYellow};
  opacity: 0;
  pointer-events: none;
  top: ${props => props.top};
  left: ${props => props.left};
  font-size: ${props => props.size};
  animation: ${twinkleAnimation} ${props => props.duration}s ease-in-out infinite ${props => props.delay}s;
`;

export const CrownIcon = styled(FaCrown)`
  font-size: 2.5rem;
  color: gold;
  filter: drop-shadow(2px 2px 0 black) drop-shadow(-1px -1px 0 black);
`;

export const ProUserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;