import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import AppRouter from './routes/router';
import './i18n/config';
import { AuthProvider } from './contexts/AuthContext';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppWrapper>
          <ContentWrapper>
            <AppRouter />
          </ContentWrapper>
        </AppWrapper>
      </Router>
    </AuthProvider>
  );
}

export default App;