import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Spinner } from '../components/auth/styles/authModalStyles';

interface ProtectedRouteProps {
  children: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { authState, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div><Spinner /></div>;
  }

  if (!authState) {
    return <div>No auth state available</div>;
  }

  if (!authState.isAuthenticated) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;