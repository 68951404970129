// SvSelect.tsx
import styled, { css } from 'styled-components';

interface SelectProps {
  size?: 'small' | 'medium' | 'large';
}

const getSizeStyles = (size: SelectProps['size'] = 'medium') => {
  switch (size) {
    case 'small':
      return css`
        padding: 0.4rem 0.8rem;
        font-size: 0.75rem;
        height: 32px;
      `;
    case 'large':
      return css`
        padding: 0.8rem 1.2rem;
        font-size: 0.85rem;
        height: 53px;
      `;
    default:
      return css`
        padding: 0.6rem 1rem;
        font-size: 0.85rem;
        height: 40px;
      `;
  }
};

const SvSelect = styled.select<SelectProps>`
  ${({ theme, size }) => css`
    width: 100%;
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: ${theme.input.borderRadius};
    appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%;
    background-size: 0.65em auto;

    ${getSizeStyles(size)}

    &:focus {
      outline: none;
      border-color: ${theme.input.variants.outline.focusBorderColor};
      box-shadow: ${theme.input.variants.outline.focusBoxShadow};
    }
  `}
`;

export const SvOption = styled.option<SelectProps>`
  ${({ size }) => getSizeStyles(size)}
`;

export default SvSelect;