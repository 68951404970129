import { styled } from "styled-components";
import theme from "../../../styles/theme";

export const WatchlistContainer = styled.div`
  width: 300px;
  background-color: white;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: fit-content;
  border: 1px solid #000;

  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const WatchlistHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const WatchlistTitle = styled.h3`
  font-size: 1.1rem;
  color: #4a4a4a;
  margin: 0 0 0 0.5rem;
`;

export const ClearButton = styled.button`
  background-color: ${theme.colors.primaryPink};
  color: #4a4a4a;
  border: 1px solid #000;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.7rem;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${theme.colors.primaryPinkHover};
  }
`;

export const WatchlistUnit = styled.div`
  padding: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: #f8f8f8;
  }
  &:last-child {
    border-bottom: none;
  }
`;

export const GemInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const GemSymbol = styled.span`
  color: #888;
`;

export const RemoveButton = styled.button`
  background-color: ${theme.colors.primaryPink};
  color: #4a4a4a;
  border: none;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.7rem;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${theme.colors.primaryPinkHover};
  }
`;

export const EmptyWatchlistMessage = styled.p`
  color: #888;
  font-size: 0.9rem;
  text-align: center;
  margin: 1rem 0;
`;

export const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

export const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const ShowFirstToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.colors.primaryPinkHover};
  margin-right: 8px;
`;

export const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + & {
    background-color: ${props => props.theme.colors.primaryPink};
  }

  input:checked + &:before {
    transform: translateX(20px);
  }
`;