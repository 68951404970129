import { Asset, Portfolio, Transaction } from "../../../types/portfolioTypes";

export const calculateTotalValue = (transactions: Transaction[]): number => {
  return transactions.reduce((total, transaction) => {
    const transactionValue = transaction.amount * transaction.price;
    return total + transactionValue;
  }, 0);
};

export const calculateAssetsFromTransactions = (transactions: Transaction[]): Asset[] => {
  const assetMap: { [key: string]: Asset } = {};

  transactions.forEach(transaction => {
    const { assetSymbol, assetName, amount, price } = transaction;

    if (!assetMap[assetSymbol]) {
      assetMap[assetSymbol] = {
        symbol: assetSymbol,
        name: assetName,
        amount: 0,
        price: 0,
        transactions: [],
      };
    }

    const asset = assetMap[assetSymbol];
    asset.transactions.push(transaction);
    
    // Assuming price should be the latest transaction price for the asset
    asset.price = price;

    // Adjust the amount based on the transaction type
    if (transaction.type === 'buy') {
      asset.amount += amount;
    } else if (transaction.type === 'sell') {
      asset.amount -= amount;
    }
  });

  return Object.values(assetMap);
};

export const calculateBalance = (transactions: Transaction[], currency: string): number => {
  return transactions.reduce((total, transaction) => {
    if (transaction.assetSymbol === currency) {
      const transactionValue = transaction.amount * transaction.price;
      return transaction.type === 'buy' 
        ? total - transactionValue  // Subtract when buying (spending cash)
        : total + transactionValue; // Add when selling (receiving cash)
    }
    return total;
  }, 0);
};

export const getCurrency = (lang: string): string => {
  switch (lang) {
    case 'nb':
      return 'NOK';
    default:
      return 'USD';
  }
};

export const exchangeRateNOKUSD = 10.5;

export const validateAmount = (value: string): boolean => {
  const numValue = parseFloat(value);
  return !isNaN(numValue) && numValue > 0;
};

export const formatCurrency = (amount: number, displayNOK: boolean): string => {
  const formatter = new Intl.NumberFormat(displayNOK ? 'no-NO' : 'en-US', {
    style: 'currency',
    currency: displayNOK ? 'NOK' : 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const convertedAmount = displayNOK ? amount * exchangeRateNOKUSD : amount;
  return formatter.format(convertedAmount);
};

export const initialPortfolio: Portfolio = {
  _id: '',
  userId: '',
  name: '',
  cashBalance: 0, 
  assets: [],
  transactions: [],
  lastUpdated: new Date().toISOString()
};

export const demoPortfolio: Portfolio = {
  _id: '1234',
  userId: 'demo',
  name: 'demo-portfolio',
  cashBalance: 100000, 
  assets: [],
  transactions: [],
  lastUpdated: new Date().toISOString()
};

export enum AssetPricesUSD {
  BTC = 30000,
  ETH = 2000,
  ADA = 1.5,
  XRP = 0.5,
  DOT = 20,
  LTC = 150,
  LINK = 25,
  XLM = 0.3,
  DOGE = 0.2,
  UNI = 15
}

export const initialTransactions: Transaction[] = [
  {
    id: '1',
    assetSymbol: 'BTC',
    assetName: 'Bitcoin',
    type: 'buy',
    amount: 0.5,
    price: AssetPricesUSD.BTC,
    date: new Date().toISOString()
  },
  { 
    id: '2',
    assetSymbol: 'ETH',
    assetName: 'Ethereum',
    type: 'buy',
    amount: 5,
    price: AssetPricesUSD.ETH,
    date: new Date().toISOString()
  }
];

  // Chart data
export const tempChartData = {
  BTC: [
    { date: '2023-05-01', price: 29000 },
    { date: '2023-05-02', price: 28500 },
    { date: '2023-05-03', price: 29200 },
    { date: '2023-05-04', price: 29800 },
    { date: '2023-05-05', price: 30100 },
  ],
  ETH: [
    { date: '2023-05-01', price: 1900 },
    { date: '2023-05-02', price: 1850 },
    { date: '2023-05-03', price: 1920 },
    { date: '2023-05-04', price: 1980 },
    { date: '2023-05-05', price: 2010 },
  ],
};

export const availableAssets: Asset[] = [
  {
    symbol: 'BTC',
    name: 'Bitcoin',
    price: AssetPricesUSD.BTC,
    amount: 0,
    transactions: []
  },
  {
    symbol: 'ETH',
    name: 'Ethereum',
    price: AssetPricesUSD.ETH,
    amount: 0,
    transactions: []
  },
  {
    symbol: 'ADA',
    name: 'Cardano',
    price: AssetPricesUSD.ADA,
    amount: 0,
    transactions: []
  },
  {
    symbol: 'XRP',
    name: 'Ripple',
    price: AssetPricesUSD.XRP,
    amount: 0,
    transactions: []
  },
  {
    symbol: 'DOT',
    name: 'Polkadot',
    price: AssetPricesUSD.DOT,
    amount: 0,
    transactions: []
  },
  {
    symbol: 'LTC',
    name: 'Litecoin',
    price: AssetPricesUSD.LTC,
    amount: 0,
    transactions: []
  },
  {
    symbol: 'LINK',
    name: 'Chainlink',
    price: AssetPricesUSD.LINK,
    amount: 0,
    transactions: []
  },
  {
    symbol: 'XLM',
    name: 'Stellar',
    price: AssetPricesUSD.XLM,
    amount: 0,
    transactions: []
  },
  {
    symbol: 'DOGE',
    name: 'Dogecoin',
    price: AssetPricesUSD.DOGE,
    amount: 0,
    transactions: []
  },
  {
    symbol: 'UNI',
    name: 'Uniswap',
    price: AssetPricesUSD.UNI,
    amount: 0,
    transactions: []
  }
];

export const colorStuff = (assets: number, cash: number) => {
  const interpolateColor = (color1: number[], color2: number[], factor: number): number[] => {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
  };
  
  const componentToHex = (c: number): string => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  
  const rgbToHex = ([r, g, b]: number[]): string => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  };
  
  const hexToRgb = (hex: string): number[] | null => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : null;
  };
  
  const startColor = hexToRgb('#D30021');
  const endColor = hexToRgb('#490044');
  
  let COLORS: string[] = [];
  if (assets > 1 && cash > 0) {
    COLORS.push('#3B9696');
  }
  
  if (startColor && endColor) {
    // Always include start
    COLORS.push(rgbToHex(startColor));
    
    // Interpolate colors if assets > 2 (i.e., more than just start and end)
    if (assets > 1) {
      for (let i = 1; i < assets - 1; i++) {
        const factor = i / (assets - 1);
        const interpolated = interpolateColor(startColor, endColor, factor);
        COLORS.push(rgbToHex(interpolated));
      }
      // Include end
      COLORS.push(rgbToHex(endColor));
    }
  }
  
  return COLORS;
}
