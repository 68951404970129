import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaUser, FaUserPlus } from 'react-icons/fa';
import { CrownIcon } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import UpdatePricesButton from '../common/button/UpdateButton';
import ModalWrapper from './ModalWrapper';
import {
  ModalTitle,
  Form,
  Input,
  Button,
  SwitchButton,
  ErrorMessage,
  Spinner,
  CloseButton,
  InputWrapper,
  ButtonGroup,
  LoggedInContent,
  UserInfoContainer
} from './styles/authModalStyles';



interface AuthModalProps {
  source?: string;
  isOpen: boolean;
  onClose: () => void;
}



const AuthModal: React.FC<AuthModalProps> = ({ source, isOpen, onClose }) => {
  const { t } = useTranslation();
  const { user, login, register, logout, changePassword } = useAuth();

  const modalRef = useRef<HTMLDivElement>(null);
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const [infoPopup, setInfoPopup] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>(
    localStorage.getItem('userEmailLoggedIn')?.toString() || ''
  );

  const [password, setPassword] = useState<string>(
    localStorage.getItem('userPwLoggedIn')?.toString() || ''
  );
  const [password2, setPassword2] = useState<string>('');
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');



  const allowRegister = process.env.REACT_APP_ALLOW_REGISTER === 'true';

  

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  

  if (!isOpen) return null;



  const handleError = (error: unknown) => {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        const { data } = error.response;
        if (data && data.code) {
          return t(`authenticate.modal.error.DEFAULT`);
        }
      } else if (error.request) {
        return t('authenticate.modal.error.NETWORK_ERROR');
      }
    } else if (error instanceof Error) {
      return t(`authenticate.modal.error.INVALID_CREDENTIALS`);
    }
    return t('authenticate.modal.error.UNKNOWN_ERROR');
  };



  const validateEmail = (email: string): boolean => {
    const emailRegex = /^.+@.+\..{2,}$/;
    return emailRegex.test(email);
  };



  // LOGIN / REGISTER SUBMIT
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setInfoPopup(null);
    setIsLoading(true);

    if (!validateEmail(email)) {
      setIsLoading(false);
      setInfoPopup(t('authenticate.modal.error.INVALID_EMAIL'));
      return;
    }

    try {
      if (isLogin) {
        await login(email, password);
      } else {
        if (password !== password2) {
          setIsLoading(false);
          setInfoPopup(t('authenticate.modal.error.PASSWORD_MISMATCH'));
          return;
        }
        await register(email, password, username);
      }
      onClose();
    } catch (error) {
      const errorMessage = handleError(error);
      setInfoPopup(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };



  // LOGOUT
  const handleLogout = async () => {
    setInfoPopup(null);
    setIsLoading(true);
    try {
      logout();
      onClose();
    } catch (error) {
      setInfoPopup(handleError(error));
    } finally {
      setIsLoading(false);
    }
  };



  // CHANGE PASSWORD
  const handleChangePassword = async () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setInfoPopup(t('authenticate.modal.error.MISSING_FIELDS'));
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setInfoPopup(t('authenticate.modal.profile.changePassword.mismatch'));
      return;
    }

    setInfoPopup(null);
    setIsLoading(true);
    try {
      await changePassword(currentPassword, newPassword);
      setInfoPopup(t('authenticate.modal.profile.changePassword.success'));
      setIsChangingPassword(false);
    } catch (error) {
      setInfoPopup(handleError(error));
    } finally {
      setIsLoading(false);
    }
  };



  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <CloseButton onClick={onClose}>&times;</CloseButton>
      {user ? (
        <LoggedInContent>
          <ModalTitle>
            <>
              {user?.isPro ? <CrownIcon size={22} /> : <FaUser size={22} />}
              {t('authenticate.modal.profile.title')}
              {user?.isAdmin && (user?.username === 'trailvis_guy' || user?.username === 'Olly_') && 
                <UpdatePricesButton type={'crypto'}/>}
              {(user?.isAdmin && (user?.username === 'trailvis_guy' || user?.username === 'Olly_')) && 
                <UpdatePricesButton type={'stocks'}/>}
            </>
          </ModalTitle>

          <UserInfoContainer>
            <p style={{color: 'red', whiteSpace: 'nowrap'}}>
              {t('authenticate.modal.profile.greeting', { username: user?.username || 'you' })}
            </p>
            <p style={{color: 'red'}}>
              ( {user?.email} )
            </p>
          </UserInfoContainer>

          {isChangingPassword ? (
            <Form onSubmit={(e) => { e.preventDefault(); handleChangePassword(); }}>
              <InputWrapper>
                <Input
                  type="password"
                  placeholder={t('authenticate.modal.profile.changePassword.currentPassword')}
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <Input
                  type="password"
                  placeholder={t('authenticate.modal.profile.changePassword.newPassword')}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <Input
                  type="password"
                  placeholder={t('authenticate.modal.profile.changePassword.confirmNewPassword')}
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
              </InputWrapper>
              {infoPopup && <ErrorMessage>{infoPopup}</ErrorMessage>}
              <ButtonGroup>
                <Button type="submit">
                  {t('authenticate.modal.profile.changePassword.button')}
                </Button>
                <Button type="button" onClick={() => setIsChangingPassword(false)}>
                  {t('authenticate.modal.profile.changePassword.cancel')}
                </Button>
              </ButtonGroup>
            </Form>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', alignItems: 'center', justifyContent: 'space-between' }}>
              <Button onClick={() => setIsChangingPassword(true)} style={{ whiteSpace: 'nowrap' }}>
                {t('authenticate.modal.profile.changePassword.button')}
              </Button>
              <Button onClick={handleLogout}>
                {t('authenticate.modal.profile.logout.button')}
              </Button>
            </div>
          )}
        </LoggedInContent>
      ) : (
        <>
          <ModalTitle>
            {isLogin ? <FaUser size={22} /> : <FaUserPlus size={26} />}
            {' '}
            {isLogin ? t('authenticate.modal.login.title') : t('authenticate.modal.register.title')}
          </ModalTitle>
          <Form onSubmit={handleSubmit}>
            <InputWrapper>
              <Input
                type="email"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                placeholder={t('authenticate.modal.email.placeholder')}
                required
                disabled={isLoading}
              />
            </InputWrapper>
            {!isLogin && (
              <InputWrapper>
                <Input
                  type="text"
                  value={username}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                  placeholder={t('authenticate.modal.register.username')}
                  required
                  disabled={isLoading}
                />
              </InputWrapper>
            )}
            <InputWrapper>
              <Input
                type="password"
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                placeholder={t('authenticate.modal.password.placeholder')}
                required
                disabled={isLoading}
              />
            </InputWrapper>
            {!isLogin && (
              <InputWrapper>
                <Input
                  type="password"
                  value={password2}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword2(e.target.value)}
                  placeholder={t('authenticate.modal.register.confirmPassword')}
                  required
                  disabled={isLoading}
                />
              </InputWrapper>
            )}
            <Button type="submit" disabled={isLoading}>
              {isLoading ? <Spinner /> : (isLogin ? t('authenticate.modal.login.button') : t('authenticate.modal.register.button'))}
            </Button>
          </Form>
          {infoPopup && <ErrorMessage>{infoPopup}</ErrorMessage>}
          {source !== 'waitlist' && allowRegister &&
            <SwitchButton onClick={() => setIsLogin(!isLogin)} disabled={isLoading}>
              {isLogin ? t('authenticate.modal.switch.to_register') : t('authenticate.modal.switch.to_login')}
            </SwitchButton>
          }
        </>
      )}
    </ModalWrapper>
  );
};



export default AuthModal;