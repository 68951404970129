import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Transaction } from '../../../types/portfolioTypes';
import { TransactionItem } from '../pageStyle';
import PaginationButtons from '../../../components/common/button/PaginationButton';

interface TransactionListProps {
  transactions: Transaction[];
  displayCurrency: (amount: number) => string;
}

const TransactionList: React.FC<TransactionListProps> = ({ transactions, displayCurrency }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 10;

  const getCurrentTransactions = () => {
    const indexOfLastTransaction = currentPage * transactionsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
    return transactions
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      .slice(indexOfFirstTransaction, indexOfLastTransaction);
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <>
      <h2>{t('portfolio.transactions')}  ( {transactions.length} )</h2>
      <div>
        {getCurrentTransactions().map((transaction) => (
          <TransactionItem key={transaction.id}>
            <span>{t(`portfolio.transaction.${transaction.type}`)} {transaction.amount} {transaction.assetSymbol}</span>
            <span>{t('portfolio.price')}: {displayCurrency(transaction.price)}</span>
            <span>{t('portfolio.transaction.value')}: {displayCurrency(transaction.amount * transaction.price)}</span>
            <span>{new Date(transaction.date).toLocaleString()}</span>
          </TransactionItem>
        ))}
      </div>
      {transactions.length > transactionsPerPage && (
        <PaginationButtons
          currentPage={currentPage}
          totalPages={Math.ceil(transactions.length / transactionsPerPage)}
          onPageChange={paginate}
        />
      )}
    </>
  );
};

export default TransactionList;