import styled from "styled-components";
import SvSelect from "../../components/common/select/SvSelect";
import { ExpectedPriceWrapperProps } from "../../types/portfolioTypes";

export const ComponentWrapper = styled.div`
  max-width: 2200px;
  margin: 40px auto;
  padding: 0 20px;
  padding-top: 60px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ExpectedPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 16px;
`;

export const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  gap: 30px;
`;

export const RightColumn = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Section = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  height: 100%;
  display: flex;
  flex-direction: column;
  
  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

export const Header = styled.h1`
  color: #e60000;
  margin-bottom: 24px;
  font-size: 2.2rem;
  font-weight: 700;
`;

export const AssetList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const AssetItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: #f9f9f9;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const AssetInfo = styled.div`
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
`;

export const AssetName = styled.h3`
  margin: 0 0 12px 0;
  font-size: 1.3em;
  font-weight: 600;
  color: #333333;
`;

export const AssetDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AssetValue = styled.span`
  font-size: 1em;
  color: #666666;
  margin-bottom: 6px;
  padding-left: 10px;
`;

export const FullWidthSection = styled(Section)`
  margin-top: 30px;
`;

export const ChartWrapper = styled.div`
  height: 380px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
`;

export const TransactionForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
`;

export const Input = styled.input`
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  width: 100%;
  font-size: 1em;
`;

export const Select = styled.select`
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  width: 100%;
  font-size: 1em;
`;

export const Button = styled.button`
  padding: 12px 20px;
  background-color: #e60000;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 600;
  font-size: 1em;
  
  &:hover {
    background-color: #cc0000;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

export const TransactionList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
`;

export const TransactionItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  
  &:last-child {
    border-bottom: none;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const StyledSvSelect = styled(SvSelect)`
  width: 100%;
`;

export const ExpectedPriceWrapper = styled.div<ExpectedPriceWrapperProps>`
  padding: 6px 10px;
  border-radius: 4px;
  font-weight: bold;
  background-color: ${props => props.isPositive ? '#e6f4ea' : '#fce8e6'};
  color: ${props => props.isPositive ? '#137333' : '#c5221f'};
  margin-right: 10px;
`;

export const TransactionWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;