import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Layout from '../layout/Layout';
import PortfolioPage from '../pages/portfolio/PortfolioPage';
import ProtectedRoute from './ProtectedRoute';
import PremiumFeature from '../pages/premium/PremiumFeature';
import LandingPage from '../pages/landing/LandingPage';
import ContactUs from '../pages/contact/ContactUs';
import TestGemsPage from '../pages/introgems/IntroGemsPage';
import GemPage from '../pages/discovery/gemPage/GemPage';
import DiscoveryPage from '../pages/discovery/DiscoveryPage';
import WaitlistPage from '../pages/waitlist/WaitlistPage';

const ErrorFallback = ({ error }: { error: Error }) => (
  <div style={{ paddingTop: "40px" }}>
    <h1>Something went wrong:</h1>
    <pre>{error.message}</pre>
  </div>
);

const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<LandingPage />} />
        <Route path="gems" element={
            <ProtectedRoute>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <DiscoveryPage />
              </ErrorBoundary>
            </ProtectedRoute>
          } 
        />
        <Route path="gems/:pageId" element={
          <ProtectedRoute>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <GemPage />
            </ErrorBoundary>
          </ProtectedRoute>
        } />
        {/* From Landing page */}
        <Route path="intro-gems" element={<TestGemsPage />} />
        <Route path="contact" element={<ContactUs />} />

        {/* Linked from stripe */}
        <Route path="payment-success" element={<LandingPage />} />
        <Route path="success" element={<LandingPage />} />

        {/* Available but not shown in menu */}
        <Route path="portfolio" element={<PortfolioPage />} />
        <Route path="premium" element={<PremiumFeature />} />
        <Route path="waitlist" element={<WaitlistPage />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;