import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import { useInfoPopup } from '../../hooks/useInfoPopup';
import InfoPopup from '../../components/common/popup/InfoPopup';
import theme from '../../styles/theme';
import { FollowType, UserAction } from './types';
import UserActions from './UserActions';

const FollowingContainer = styled.div`
  width: 300px;
  background-color: white;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: fit-content;
  border: 1px solid #000;
  margin-top: 2rem;

  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

const FollowingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const FollowingTitle = styled.h3`
  font-size: 1.1rem;
  color: #4a4a4a;
  margin: 0 0 0 0.5rem;
`;

const UnfollowAllButton = styled.button`
  background-color: ${theme.colors.primaryPink};
  color: #4a4a4a;
  border: 1px solid #000;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.7rem;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${theme.colors.primaryPinkHover};
  }
`;

const FollowingUnit = styled.div`
  padding: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: #f8f8f8;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Username = styled.span`
  font-weight: bold;
`;

const UnfollowButton = styled.button`
  background-color: ${theme.colors.primaryPink};
  color: #4a4a4a;
  border: none;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.7rem;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${theme.colors.primaryPinkHover};
  }
`;

const EmptyFollowingMessage = styled.p`
  color: #888;
  font-size: 0.9rem;
  text-align: center;
  margin: 1rem 0;
`;

const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
`;

const ModalContent = styled.div`
  padding: 1rem;
  border-radius: 6px;
  max-width: 95%;
  max-height: 95%;
  overflow-y: auto;
  position: relative;
`;



interface FollowingProps {
  onUnfollowAll: () => Promise<void>;
  isLoading: boolean;
  localFollowing: FollowType[];
  onLocalUnfollow: (username: string) => void;
}



const Following: React.FC<FollowingProps> = ({ 
    onUnfollowAll, 
    isLoading, 
    localFollowing, 
    onLocalUnfollow 
}) => {
  const { t } = useTranslation();
  const { user, handleFollowChange } = useAuth();
  const { popups, showErrorPopup, showInfoPopup } = useInfoPopup();
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [userActions, setUserActions] = useState<Omit<UserAction, 'userId'>[]>([]);
  const modalRef = useRef<HTMLDivElement>(null);



  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        handleCloseModal();
      }
    };

    if (selectedUser) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedUser]);


  
  const handleUnfollowAll = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (user) {
      try {
        await onUnfollowAll();
      } catch (error) { 
        console.error('Error unfollowing all users.');
      }
    }
  };



  const handleUnfollow = async (e: React.MouseEvent, userId: string, username: string) => {
    e.stopPropagation();
    try {
      await handleFollowChange({ userId, username });
      onLocalUnfollow(username);
      showInfoPopup(t('following.popup.unfollowedUser', { username }));
    } catch (error) {
      showErrorPopup(t('following.popup.unfollowError'));
    }
  };



  const handleUsernameClick = (userId: string) => {
    setSelectedUser(userId);
    // Reset user actions when a new user is selected
    setUserActions([]);
  };



  const handleCloseModal = () => {
    setSelectedUser(null);
  };



  return (
    <FollowingContainer>
      <FollowingHeader>
        <FollowingTitle>{t('following.list.title')}</FollowingTitle>
        {user && localFollowing.length > 0 && (
          <UnfollowAllButton onClick={handleUnfollowAll}>
            {t('following.list.removeAll')}
          </UnfollowAllButton>
        )}
      </FollowingHeader>
      {isLoading ? (
        <LoadingIndicator>
          <LoadingSpinner />
        </LoadingIndicator>
      ) : user && localFollowing.length > 0 ? (
        localFollowing.map(followedUser => (
          <FollowingUnit key={followedUser.userId} onClick={() => handleUsernameClick(followedUser.userId)}>
            <UserInfo>
              <Username>{followedUser.username}</Username>
            </UserInfo>
            <UnfollowButton onClick={(e) => handleUnfollow(e, followedUser.userId, followedUser.username)}>
              {t('following.list.remove')}
            </UnfollowButton>
          </FollowingUnit>
        ))
      ) : (
        <EmptyFollowingMessage>
          {t('following.list.empty')}
        </EmptyFollowingMessage>
      )}
      {popups.map((popup) => (
        <InfoPopup
          key={popup.id}
          message={popup.message}
          type={popup.type}
          position={popup.position}
          timeout={popup.duration}
        />
      ))}
      {selectedUser && (
        <Modal>
          <ModalContent ref={modalRef}>
            <UserActions 
              actions={userActions} 
              setActions={setUserActions}
              selectedUser={selectedUser}
              source="following"
              handleCloseModal={handleCloseModal}
              collapsed={false}
            />
          </ModalContent>
        </Modal>
      )}
    </FollowingContainer>
  );
};

export default Following;